import React, { useState, useEffect, useRef } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  makeStyles,
  Box,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchIcon from "@material-ui/icons/Search";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CustomButton from "./CustomButton.web";
const menuDrag = require("../src/menu-drag.svg");
const deleteIcon = require("../src/delete.svg");
const addIcon = require("../src/add-icon.svg");
const dialogBackground = require("../src/dialog-background.svg");

const useStyles = makeStyles(() => ({
  roundedSelectField: {
    width: "160px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      border: (props: ICustomSearchableDropdownProps) =>
        `1px solid ${
          props.error ? "red" : props.isDark ? "#FFF" : "#000"
        } !important`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid #01AA7F !important`,
    },
    "& .MuiOutlinedInput-root .MuiSelect-icon": {
      color: (props: ICustomSearchableDropdownProps) =>
        `${props.isDark ? "#FFF" : "#000"} !important`,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiInputBase-input": {
      color: "#01AA7F !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiSelect-icon": {
      color: "#01AA7F !important",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px !important",
      fontFamily: "Poppins",
      color: (props: ICustomSearchableDropdownProps) =>
        `${props.isDark ? "#FFF" : "#000"} !important`,
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: (props: ICustomSearchableDropdownProps) =>
        props.hideIcon ? "14px" : "default",
    },
  },
  roundedSelectedSelectField: {
    width: "160px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      border: "1px solid transparent !important",
    },
    "& .MuiOutlinedInput-root .MuiInputBase-input": {
      color: "#FFF !important",
    },
    "& .MuiOutlinedInput-root .MuiSelect-icon": {
      color: "#FFF !important",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px !important",
      fontFamily: "Poppins",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: (props: ICustomSearchableDropdownProps) =>
        props.hideIcon ? "14px" : "default",
    },
  },
  textField: {
    "& input": {
      color: "#FFF !important",
      fontFamily: "Poppins",
      fontSize: "14px",
    },
  },
  menuList: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  selectRoot: {
    boxShadow: "none !important",
    backgroundColor: "transparent !important",
  },
  textFieldOutlined: {
    "& input": {
      color: "#FFF !important",
      fontFamily: "Open Sans",
      fontSize: "14px",
      fontWeight: 700,
      padding: "10px 14px",
    },
    "& fieldset": {
      borderColor: "#78716C !important",
    },
  },
}));

interface IDropdown {
  name: string;
  value: string;
}

interface ICustomSearchableDropdownProps {
  value: string;
  label?: string;
  name: string;
  error: boolean;
  options: IDropdown[];
  handleChange: (value: string, id: string) => void;
  disabled?: boolean;
  isDark: boolean;
  deleteOption: (value: string) => void;
  updateOption: (value: string, optionId: string) => void;
  hideIcon?: boolean;
}

const CustomSearchableDropdown: React.FC<ICustomSearchableDropdownProps> = ({
  value,
  label,
  error,
  name,
  options,
  handleChange,
  disabled,
  isDark,
  deleteOption,
  updateOption,
  hideIcon,
}) => {
  const classes = useStyles({
    value,
    label,
    error,
    name,
    options,
    handleChange,
    disabled,
    isDark,
    deleteOption,
    updateOption,
    hideIcon,
  });
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isOpenEditView, setIsOpenEditView] = useState(false);
  const [deleteOptionId, setDeleteOptionId] = useState<string>("");
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [updatedOptionList, setUpdatedOptionList] = useState<IDropdown[]>(
    JSON.parse(JSON.stringify(options))
  );

  const newItemRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setUpdatedOptionList(JSON.parse(JSON.stringify(options)));
  }, [options]);

  useEffect(() => {
    if (newItemRef.current) {
      newItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [updatedOptionList.length]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const getHighlightedText = (text: string, highlight: string) => {
    if (!highlight) {
      return text;
    }

    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={webStyle.highlight}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const HighlightedTextComponent = (text: string, highlight: string) => {
    return (
      <div style={{ whiteSpace: "pre-wrap" }}>
        {getHighlightedText(text, highlight)}
      </div>
    );
  };

  const handleSelectOption = (optionName: string, optionId: string) => {
    setSearchText("");
    handleChange(optionName, optionId);
  };

  const handleCloseMenu = () => {
    setOpen(false);
    setSearchText("");
    setIsOpenEditView(false);
    setUpdatedOptionList(JSON.parse(JSON.stringify(options)));
  };

  const toggleAddEdit = (event: any) => {
    event.stopPropagation();
    setIsOpenEditView(!isOpenEditView);
  };

  const handleDeleteOption = (event: any, optionValue: string) => {
    event.stopPropagation();
    setDeleteOptionId(optionValue);
    setIsOpenDeleteDialog(true);
  };

  const closeDeleteOptionDialog = () => {
    setDeleteOptionId("");
    setIsOpenDeleteDialog(false);
  };

  const deleteOptionItem = () => {
    const optionId = deleteOptionId;
    const optionItem = options.find((object) => object.value === optionId);
    setIsOpenDeleteDialog(false);
    setDeleteOptionId("");
    if (optionItem) {
      deleteOption(optionId);
    } else {
      const newOptions = updatedOptionList;
      const optionItemIndex = newOptions.findIndex(
        (object) => object.value === optionId
      );
      if (optionItemIndex > -1) {
        newOptions.splice(optionItemIndex, 1);
        setUpdatedOptionList(newOptions);
      }
    }
  };

  const handleEditOption = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const optionList = [...updatedOptionList];
    optionList[index].name = event.target.value;
    setUpdatedOptionList(optionList);
  };

  const saveUpdatedOption = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const eValue = event.target.value;
    const optionList = [...updatedOptionList];
    optionList[index].name = eValue;
    setUpdatedOptionList(optionList);
    const oldOptions: IDropdown[] = JSON.parse(JSON.stringify(options));
    const activeOption = oldOptions.find(
      (object) => object.value === optionList[index].value
    );
    if (eValue) {
      if (activeOption && activeOption.name !== eValue) {
        updateOption(eValue, optionList[index].value);
      } else {
        updateOption(eValue, "");
      }
    }
  };

  const addNewOption = () => {
    if (updatedOptionList.every((item) => item.name !== "")) {
      setUpdatedOptionList([
        ...updatedOptionList,
        { name: "", value: new Date().getTime().toString() },
      ]);
    }
  };

  return (
    <>
      <FormControl
        variant="outlined"
        className={
          options.map((item) => item.name).includes(value)
            ? classes.roundedSelectedSelectField
            : classes.roundedSelectField
        }
      >
        <InputLabel error={error}>{label}</InputLabel>
        <Select
          label={label}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={handleCloseMenu}
          name={name}
          value={
            options.map((item) => item.name).includes(value) ? value : null
          }
          error={error}
          style={{
            borderRadius: "50px",
            height: "30px",
            background: options.map((item) => item.name).includes(value)
              ? "#00AED2"
              : "transparent",
          }}
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null,
            MenuListProps: {
              classes: {
                root: classes.menuList,
              },
            },
          }}
          IconComponent={(props) =>
            !hideIcon ? <ArrowDropDownIcon {...props} /> : null
          }
          data-test-id={name}
          renderValue={(selectedValue: any) => {
            return !selectedValue ? "Select Option" : selectedValue;
          }}
          disabled={disabled}
          displayEmpty
          classes={{ root: classes.selectRoot }}
        >
          {isOpenEditView ? (
            <Box borderRadius={"4px"} width={"284px"} bgcolor={"#57534E"}>
              <Box
                maxHeight={"210px"}
                style={webStyle.listWrapper}
                overflow={"auto"}
                onClick={(e) => e.stopPropagation()}
              >
                {updatedOptionList.map((object, index: number) => (
                  <Box
                    key={object.value + "add-edit"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    padding={"8px 16px"}
                  >
                    <Box>
                      <img src={menuDrag} alt="menu-drag" width={12} />
                    </Box>
                    <Box padding={"0px 8px"} maxWidth={"184px"}>
                      <TextField
                        className={classes.textFieldOutlined}
                        variant="outlined"
                        placeholder="Enter your text"
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={(e) => e.stopPropagation()}
                        onChange={(e) => handleEditOption(e, index)}
                        onBlur={(e) => saveUpdatedOption(e, index)}
                        value={object.name}
                        inputRef={
                          index === updatedOptionList.length - 1
                            ? newItemRef
                            : null
                        }
                      />
                    </Box>
                    <Box
                      onClick={(e) => handleDeleteOption(e, object.value)}
                      style={webStyle.pointer}
                    >
                      <img src={deleteIcon} alt="delete" width={14} />
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                padding={"8px 16px"}
                style={{ ...webStyle.listWrapper, ...webStyle.pointer }}
                display={"flex"}
                alignItems={"center"}
                onClick={(e) => e.stopPropagation()}
              >
                <Button
                  style={webStyle.addRowBtn}
                  variant="outlined"
                  onClick={addNewOption}
                  disabled={updatedOptionList.some((item) => item.name === "")}
                >
                  <span style={webStyle.addImage}>
                    <img src={addIcon} height={15} width={15} alt="add" />
                  </span>
                  Add another item
                </Button>
              </Box>
            </Box>
          ) : (
            <Box borderRadius={"4px"} width={"225px"}>
              <Box style={webStyle.searchField}>
                <TextField
                  className={classes.textField}
                  variant="standard"
                  placeholder="Enter your text"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={webStyle.searchIcon} />
                      </InputAdornment>
                    ),
                  }}
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleTextChange}
                  onKeyDown={(e) => e.stopPropagation()}
                  value={searchText}
                />
              </Box>
              <Box
                maxHeight={"152px"}
                style={webStyle.listWrapper}
                overflow={"auto"}
              >
                {options
                  .filter((object) =>
                    object.name.toLowerCase().includes(searchText.toLowerCase())
                  )
                  .map((item: IDropdown) => (
                    <MenuItem
                      key={`${item.value}-option`}
                      value={item.name}
                      data-test-id="menu-list"
                      onClick={() => handleSelectOption(item.name, item.value)}
                      style={webStyle.menuItem}
                    >
                      {HighlightedTextComponent(item.name, searchText)}
                    </MenuItem>
                  ))}
              </Box>
              <Box
                borderTop={"1px solid #FFF"}
                padding={"8px 16px"}
                style={{ ...webStyle.listWrapper, ...webStyle.pointer }}
                display={"flex"}
                alignItems={"center"}
                onClick={(e) => toggleAddEdit(e)}
              >
                <Box paddingRight={"8px"}>
                  <CreateOutlinedIcon />
                </Box>
                <Box>Add / Edit Options</Box>
              </Box>
            </Box>
          )}
        </Select>
      </FormControl>
      {isOpenDeleteDialog && (
        <Dialog
          open={isOpenDeleteDialog}
          PaperProps={{
            style: webStyle.deleteDialogPaper,
          }}
          fullWidth
        >
          <DialogContent style={webStyle.deleteDialogContent}>
            <Box style={webStyle.dialogDescription} padding={"0px 0px 32px"}>
              Are you sure you want to delete this option? This will clear the
              selection on all rows that have selected this option
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box paddingRight={"8px"}>
                <CustomButton
                  variant="outlined"
                  label="Cancel"
                  onClick={closeDeleteOptionDialog}
                  width={173}
                />
              </Box>
              <Box>
                <CustomButton
                  variant="contained"
                  label="Delete Option"
                  onClick={deleteOptionItem}
                  width={173}
                />
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const webStyle = {
  searchField: {
    backgroundColor: "#44403C",
    padding: "8px 16px",
    borderBottom: "1px solid #A8A29E",
  },
  searchIcon: {
    color: "#78716C",
  },
  listWrapper: {
    backgroundColor: "#57534E",
    fontSize: "14px",
    fontFamily: "Open Sans",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFF",
  },
  pointer: {
    cursor: "pointer",
  },
  highlight: {
    color: "#FFD700",
  },
  menuItem: {
    fontFamily: "Open Sans",
    fontWeight: 700,
    lineHeight: "22px",
    fontSize: "14px",
    minHeight: "inherit",
  },
  addImage: {
    paddingRight: "10px",
  },
  addRowBtn: {
    border: "1px solid #78716C",
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textTransform: "none",
    borderRadius: "4px",
    paddingTop: "7px",
    paddingBottom: "7px",
  } as CSSProperties,
  deleteDialogPaper: {
    borderRadius: "16px",
    backgroundColor: "#001327",
    width: "418px",
  },
  deleteDialogContent: {
    backgroundImage: `url(${dialogBackground})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    padding: "32px",
  },
  dialogDescription: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: 1.5,
    color: "#FFF",
  },
};

export default CustomSearchableDropdown;
