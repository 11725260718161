import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

interface S {
  activeTab: string;
  sidebarWidth: number;
  nextBillingDate: string;
  subscriptionStatus: string;
  isOpenStripeInterface: boolean;
  showSuccessSnackbar: boolean;
  subscriptionDateLabel: string;
}

interface SS {}

export default class SettingsController extends BlockComponent<Props, S, SS> {
  checkSubscriptionStatusApiId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeTab: configJSON.settingsTabList[0],
      sidebarWidth: 100,
      nextBillingDate: "-",
      subscriptionStatus: "Not Active",
      isOpenStripeInterface: false,
      showSuccessSnackbar: false,
      subscriptionDateLabel: configJSON.nextBillingDate,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    this.getSubscriptionDetails();
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.checkSubscriptionStatusApiId) {
      if (
        responseJson &&
        responseJson.subscription &&
        responseJson.subscription.end_date
      ) {
        const isInActive = responseJson.subscription.inactive || false;
        let date = moment(responseJson.subscription.end_date, "MMMM D, YYYY");
        date = isInActive ? date : date.add(1, "days");
        let newDate = date.format("MMMM D, YYYY");
        this.setState({
          nextBillingDate: newDate,
          subscriptionStatus: isInActive ? "Inactive" : "Active",
          subscriptionDateLabel: isInActive
            ? configJSON.subscriptionEndDate
            : configJSON.nextBillingDate,
        });
      }
    }
  }

  toggleSuccessSnackbar = (isShow: boolean) => {
    this.setState({
      showSuccessSnackbar: isShow,
      isOpenStripeInterface: isShow ? false : this.state.isOpenStripeInterface,
    });
  };

  onOpenStripeInterface = () => {
    this.setState({ isOpenStripeInterface: true });
  };

  onCloseStripeInterface = () => {
    this.setState({ isOpenStripeInterface: false });
  };

  onTabChange = (tabName: string) => {
    this.setState({ activeTab: tabName });
  };

  onPageResize = (isSidebarOpen: boolean) => {
    this.setState({ sidebarWidth: isSidebarOpen ? 241 : 100 });
  };

  onCancelSubscription = (isImmediate: boolean) => {
    if (isImmediate) {
      this.handleSettingsNavigation("Home");
    } else {
      this.getSubscriptionDetails();
      this.onCloseStripeInterface();
    }
  };

  getSubscriptionDetails = async () => {
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const getSubscriptionDetailsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.checkSubscriptionStatusApiId =
      getSubscriptionDetailsRequestMsg.messageId;

    getSubscriptionDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionStatusApiEndPoint
    );

    getSubscriptionDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getSubscriptionDetailsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(
      getSubscriptionDetailsRequestMsg.id,
      getSubscriptionDetailsRequestMsg
    );
  };

  handleSettingsNavigation = (pathString: string) => {
    const routeTargetMsg = new Message(getName(MessageEnum.NavigationMessage));
    routeTargetMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pathString
    );
    routeTargetMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(routeTargetMsg);
  };
}
