import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import {
  loadStripe,
  Stripe,
  StripeCardNumberElement,
  StripeElements,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { FormEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
import { CardNumberElement } from "@stripe/react-stripe-js";

export const configJSON = require("./config");

export interface Props {
  onCloseDialog: () => void;
  onSuccess: () => void;
  onCancelSubscription: (isImmediate: boolean) => void;
}

interface ICardItem {
  payment_id: string;
  card_number: string;
  card_type: string;
  default: boolean;
}

export interface S {
  isLoading: boolean;
  cardListItems: ICardItem[];
  isOpenCardListView: boolean;
  selectedCard: string;
  errorMsg: string;
  stripeAction: string;
}

export interface SS {}

export default class EditStripeSubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCardListApiId: string = "";
  deleteCardApiId: string = "";
  addNewCardApiId: string = "";
  updateActiveCardApiId: string = "";
  cancelSubscriptionApiId: string = "";
  cancelSubscriptionAndLogoutApiId: string = "";
  logoutApiId: string = "";
  stripePromise = loadStripe(configJSON.stripeKey);

  options: StripeElementsOptions = {
    mode: configJSON.optionMode,
    amount: configJSON.optionAmount,
    currency: configJSON.optionCurrency,
    paymentMethodCreation: configJSON.optionPaymentMethod,
    appearance: {},
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: false,
      cardListItems: [],
      isOpenCardListView: true,
      selectedCard: "",
      errorMsg: "",
      stripeAction: "edit",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getAllCardList();
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getCardListApiId) {
      this.toggleLoader(false);
      if (responseJson && responseJson.attached_cards) {
        const activeCard = responseJson.attached_cards.find(
          (cardItem: ICardItem) => cardItem.default
        );

        this.setState({
          cardListItems: responseJson.attached_cards,
          selectedCard: activeCard?.payment_id || "",
        });
      }
    }

    if (apiRequestCallId === this.updateActiveCardApiId) {
      this.setUpdateDefaultCardResponse(responseJson);
    }

    if (apiRequestCallId === this.deleteCardApiId) {
      this.setRemoveCardResponse(responseJson);
    }

    if (apiRequestCallId === this.addNewCardApiId) {
      this.setAddNewCardResponse(responseJson);
    }

    if (apiRequestCallId === this.cancelSubscriptionApiId) {
      this.setCancelSubscriptionResponse(responseJson);
    }
  }

  setCancelSubscriptionResponse = (responseObject: { message?: string }) => {
    this.toggleLoader(false);
    if (
      responseObject &&
      responseObject?.message === configJSON.cancelAtMidMonthSuccessMsg
    ) {
      this.props.onCancelSubscription(false);
    }
  };

  setUpdateDefaultCardResponse = (responseObject: { message?: string }) => {
    this.toggleLoader(false);
    if (
      responseObject &&
      responseObject?.message === configJSON.updateCardSuccessMsg
    ) {
      this.props.onSuccess();
    }
  };

  setRemoveCardResponse = (responseObject: { message?: string }) => {
    this.toggleLoader(false);
    if (
      responseObject &&
      responseObject?.message === configJSON.deleteCardSuccessMsg
    ) {
      this.getAllCardList();
    }
  };

  setAddNewCardResponse = (responseObject: {
    message?: string;
    error?: string;
  }) => {
    this.toggleLoader(false);
    if (
      responseObject &&
      responseObject?.message === configJSON.addCardSuccessMsg
    ) {
      this.getAllCardList();
      this.toggleShowCardView();
    } else if (responseObject) {
      const errorMsg = responseObject?.message || responseObject?.error;
      this.setState({ errorMsg: errorMsg as string }, () => {
        setTimeout(() => {
          this.setState({ errorMsg: "" });
        }, 3000);
      });
    }
  };

  onCloseStripeGateway = () => {
    this.props.onCloseDialog();
  };

  toggleLoader = (isLoading: boolean) => {
    this.setState({ isLoading: isLoading });
  };

  toggleShowCardView = () => {
    this.setState({ isOpenCardListView: !this.state.isOpenCardListView });
  };

  handleChangeDefaultCard = (
    event: React.ChangeEvent<HTMLInputElement>,
    cardId: string
  ) => {
    this.setState({ selectedCard: cardId });
  };

  handleChangeStripeAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ stripeAction: (event.target as HTMLInputElement).value });
  };

  handleSubmit = async (
    event: FormEvent,
    stripe: Stripe | null,
    elements: StripeElements | null
  ) => {
    this.toggleLoader(true);
    event.preventDefault();

    if (!stripe || !elements) {
      this.toggleLoader(false);
      return;
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      this.toggleLoader(false);
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { error, token } = await stripe.createToken(
      cardElement as StripeCardNumberElement
    );
    if (error && error.message) {
      this.setState({ errorMsg: error.message, isLoading: false }, () => {
        setTimeout(() => {
          this.setState({ errorMsg: "" });
        }, 3000);
      });
    }
    if (token && token.id) {
      this.addNewCardForPayment(token.id);
    }
  };

  getAllCardList = async () => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const getAllCardListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCardListApiId = getAllCardListRequestMsg.messageId;

    getAllCardListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.showCardListApiEndPoint
    );

    getAllCardListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllCardListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(
      getAllCardListRequestMsg.id,
      getAllCardListRequestMsg
    );
  };

  updateDefaultCardForPayment = async () => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const updateActiveCardRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateActiveCardApiId = updateActiveCardRequestMsg.messageId;

    updateActiveCardRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setDefaultCardApiEndPoint
    );

    updateActiveCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    updateActiveCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.confirmPaymentMethod
    );
    updateActiveCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        card_token: this.state.selectedCard,
      })
    );

    runEngine.sendMessage(
      updateActiveCardRequestMsg.id,
      updateActiveCardRequestMsg
    );
  };

  addNewCardForPayment = async (cardToken: string) => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const addCardRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addNewCardApiId = addCardRequestMsg.messageId;

    addCardRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addNewCardApiEndPoint
    );

    addCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    addCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.confirmPaymentMethod
    );
    addCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        card_token: cardToken,
      })
    );

    runEngine.sendMessage(addCardRequestMsg.id, addCardRequestMsg);
  };

  deleteCard = async (cardToken: string) => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const removeCardRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCardApiId = removeCardRequestMsg.messageId;

    removeCardRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCardApiEndPoint + cardToken
    );

    removeCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    removeCardRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(removeCardRequestMsg.id, removeCardRequestMsg);
  };

  cancelSubscriptionAtMidMonth = async () => {
    this.toggleLoader(true);
    const headers = {
      token: await getStorageData("token"),
      "Content-Type": configJSON.apiContentType,
    };

    const cancelSubscriptionRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelSubscriptionApiId = cancelSubscriptionRequestMsg.messageId;

    cancelSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelSubscriptionMidMonthApiEndPoint
    );

    cancelSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    cancelSubscriptionRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(
      cancelSubscriptionRequestMsg.id,
      cancelSubscriptionRequestMsg
    );
  };
}
