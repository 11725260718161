import React, { CSSProperties } from "react";
import EditStripeSubscriptionController, {
  Props,
  configJSON,
} from "./EditStripeSubscriptionController.web";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import {
  Elements,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { radioCheckedIcon, radioIcon, trashIcon } from "./assets";

const elementStyles = {
  base: {
    color: "#FFF",
    fontFamily: "Open Sans, sans-serif",
    fontSize: "16px",
    backgroundColor: "#001327",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#fa755a",
  },
};

const cardElementOptions = {
  style: elementStyles,
};

export default class EditStripeSubscription extends EditStripeSubscriptionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isOpenCardListView,
      isLoading,
      cardListItems,
      selectedCard,
      errorMsg,
      stripeAction,
    } = this.state;
    return (
      <Dialog
        open={true}
        PaperProps={{
          style: webStyle.dialogPaper,
        }}
        fullWidth
      >
        <DialogContent style={webStyle.dialogContent}>
          <Box style={webStyle.btnList}>
            <Button onClick={() => this.addNewCardForPayment("")} />
            <Button onClick={(event) => this.handleSubmit(event, null, null)} />
          </Box>
          {isLoading && (
            <Box>
              <Backdrop style={{ zIndex: 9999 }} open={isLoading}>
                <CircularProgress size={60} style={webStyle.loader} />
              </Backdrop>
            </Box>
          )}
          {isOpenCardListView && (
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                style={webStyle.dialogHeader}
              >
                <Box
                  style={{
                    ...webStyle.dialogTitle,
                    ...webStyle.dialogTitleWithBorder,
                  }}
                >
                  {configJSON.editOrCancelSubscription}
                </Box>
              </Box>
              <Box style={webStyle.actionContainer} margin={"16px 3px"}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="action"
                    name="action"
                    value={stripeAction}
                    onChange={this.handleChangeStripeAction}
                    style={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="edit"
                      control={
                        <Radio
                          icon={
                            <img
                              src={radioIcon}
                              alt="radio"
                              width={20}
                              height={20}
                            />
                          }
                          checkedIcon={
                            <img
                              src={radioCheckedIcon}
                              alt="radio"
                              width={20}
                              height={20}
                            />
                          }
                        />
                      }
                      label={
                        <Box style={webStyle.radioLabel}>
                          {configJSON.editSubscription}
                        </Box>
                      }
                    />
                    <FormControlLabel
                      value="cancel"
                      control={
                        <Radio
                          icon={
                            <img
                              src={radioIcon}
                              alt="radio"
                              width={20}
                              height={20}
                            />
                          }
                          checkedIcon={
                            <img
                              src={radioCheckedIcon}
                              alt="radio"
                              width={20}
                              height={20}
                            />
                          }
                        />
                      }
                      label={
                        <Box style={webStyle.radioLabel}>
                          {configJSON.cancelSubscription}
                        </Box>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {stripeAction === "edit" ? (
                <Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    marginBottom={"8px"}
                    marginTop={"8px"}
                    paddingRight={"3px"}
                  >
                    <Button
                      style={webStyle.saveBtn}
                      onClick={this.toggleShowCardView}
                    >
                      {configJSON.addNewCard}
                    </Button>
                  </Box>
                  <Box
                    maxHeight={"256px"}
                    overflow={"auto"}
                    padding={"0px 3px"}
                  >
                    {cardListItems.map((item) => (
                      <Box
                        key={item.payment_id}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        style={
                          item.default
                            ? webStyle.defaultCard
                            : webStyle.cardItem
                        }
                        marginBottom={"8px"}
                      >
                        <Box display={"flex"} alignItems="center">
                          <Box paddingRight={"16px"}>
                            •••• •••• •••• {item.card_number}
                          </Box>
                          {item.default && (
                            <Box style={webStyle.activeTag}>Active</Box>
                          )}
                        </Box>
                        {!item.default && (
                          <Box display={"flex"} alignItems={"center"}>
                            <Box marginRight={"8px"}>
                              <Radio
                                checked={selectedCard === item.payment_id}
                                onChange={(event) =>
                                  this.handleChangeDefaultCard(
                                    event,
                                    item.payment_id
                                  )
                                }
                                name="radio"
                                icon={
                                  <img
                                    src={radioIcon}
                                    alt="radio"
                                    width={20}
                                    height={20}
                                  />
                                }
                                checkedIcon={
                                  <img
                                    src={radioCheckedIcon}
                                    alt="radio"
                                    width={20}
                                    height={20}
                                  />
                                }
                                value={item.payment_id}
                                disableRipple
                              />
                            </Box>
                            <Box
                              style={webStyle.pointer}
                              data-test-id="delete-card"
                              onClick={() => this.deleteCard(item.payment_id)}
                            >
                              <img src={trashIcon} alt="trash" height={20} />
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      flexDirection: {
                        xs: "column-reverse",
                        sm: "row",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      },
                    }}
                    marginTop={"16px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box
                      sx={{
                        margin: {
                          xs: "8px 0px 0px",
                          sm: "0px 16px 0px 0px",
                          md: "0px 16px 0px 0px",
                          lg: "0px 16px 0px 0px",
                          xl: "0px 16px 0px 0px",
                        },
                      }}
                    >
                      <Button
                        onClick={this.onCloseStripeGateway}
                        variant={"outlined"}
                        style={webStyle.cancelBtn}
                        disabled={isLoading}
                      >
                        {configJSON.close}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={this.updateDefaultCardForPayment}
                        style={webStyle.saveBtn}
                        disabled={
                          selectedCard ===
                          cardListItems.find((object) => object.default)
                            ?.payment_id
                        }
                      >
                        {configJSON.setAsActive}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Box padding={"0px 3px"}>
                    <Box
                      style={webStyle.radioLabel}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      fontWeight={700}
                    >
                      {configJSON.cancelFutureSubscription}
                    </Box>
                    <Box style={webStyle.radioLabelDescription}>
                      {configJSON.cancelAtMidDescription}
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      marginTop={"16px"}
                      justifyContent={"center"}
                      sx={{
                        flexDirection: {
                          xs: "column-reverse",
                          sm: "row",
                          md: "row",
                          lg: "row",
                          xl: "row",
                        },
                      }}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Box
                        sx={{
                          margin: {
                            xs: "8px 0px 0px",
                            sm: "0px 16px 0px 0px",
                            md: "0px 16px 0px 0px",
                            lg: "0px 16px 0px 0px",
                            xl: "0px 16px 0px 0px",
                          },
                        }}
                      >
                        <Button
                          variant={"outlined"}
                          onClick={this.onCloseStripeGateway}
                          style={{
                            ...webStyle.cancelBtn,
                            ...webStyle.closeBtn,
                          }}
                        >
                          {configJSON.close}
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant={"outlined"}
                          onClick={this.cancelSubscriptionAtMidMonth}
                          style={webStyle.cancelSubscriptionBtn}
                          data-test-id="cancel-subscription"
                        >
                          {configJSON.cancelSubscription}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          {!isOpenCardListView && (
            <Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                data-test-id="add-card-view"
                style={webStyle.dialogTitle}
              >
                {configJSON.addCardBtn}
              </Box>
              <Elements stripe={this.stripePromise} options={this.options}>
                <ElementsConsumer>
                  {({ stripe, elements }) => (
                    <form
                      onSubmit={(event) =>
                        this.handleSubmit(event, stripe, elements)
                      }
                    >
                      {errorMsg && (
                        <Box
                          style={webStyle.validationError}
                          data-test-id="error-message"
                        >
                          {errorMsg}
                        </Box>
                      )}
                      <Box marginBottom={"20px"}>
                        <label style={webStyle.labelStyle}>
                          {configJSON.cardNumber}
                        </label>
                        <div style={webStyle.containerStyle}>
                          <CardNumberElement options={cardElementOptions} />
                        </div>
                      </Box>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Box width={"100%"} marginRight={"8px"}>
                          <Box marginBottom={"20px"}>
                            <label style={webStyle.labelStyle}>
                              {configJSON.expiryDate}
                            </label>
                            <div style={webStyle.containerStyle}>
                              <CardExpiryElement options={cardElementOptions} />
                            </div>
                          </Box>
                        </Box>
                        <Box width={"100%"} marginLeft={"8px"}>
                          <Box marginBottom={"20px"}>
                            <label style={webStyle.labelStyle}>
                              {configJSON.securityCode}
                            </label>
                            <div style={webStyle.containerStyle}>
                              <CardCvcElement options={cardElementOptions} />
                            </div>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        marginTop={"16px"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        sx={{
                          flexDirection: {
                            xs: "column-reverse",
                            sm: "row",
                            md: "row",
                            lg: "row",
                            xl: "row",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            margin: {
                              xs: "8px 0px 0px",
                              sm: "0px 16px 0px 0px",
                              md: "0px 16px 0px 0px",
                              lg: "0px 16px 0px 0px",
                              xl: "0px 16px 0px 0px",
                            },
                          }}
                        >
                          <Button
                            onClick={this.onCloseStripeGateway}
                            variant={"outlined"}
                            style={webStyle.cancelBtn}
                            disabled={isLoading}
                          >
                            {configJSON.close}
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            type="submit"
                            disabled={isLoading}
                            style={webStyle.saveBtn}
                          >
                            {configJSON.addCardBtn}
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  )}
                </ElementsConsumer>
              </Elements>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const webStyle = {
  saveBtn: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    background: "linear-gradient(99deg, #01AA7F 2.64%, #00AED2 100%)",
    lineHeight: "22px",
    textTransform: "none",
    fontFamily: "Open Sans",
    borderRadius: "8px",
    paddingBottom: "8px",
    paddingTop: "8px",
    width: "144px",
  } as CSSProperties,
  cancelBtn: {
    color: "#FFF",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "none",
    fontFamily: "Open Sans",
    borderRadius: "8px",
    paddingBottom: "7px",
    paddingTop: "7px",
    width: "144px",
    border: "1px solid #FFF",
  } as CSSProperties,
  closeBtn: {
    width: "178px",
  },
  cancelSubscriptionBtn: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    textTransform: "none",
    fontFamily: "Open Sans",
    borderRadius: "8px",
    paddingBottom: "7px",
    paddingTop: "7px",
    width: "178px",
    border: "1px solid #DC2626",
    color: "#DC2626",
  } as CSSProperties,
  dialogPaper: {
    borderRadius: "16px",
    width: "600px",
    backgroundColor: "#001327",
  },
  dialogContent: {
    padding: "16px 32px",
  },
  btnList: {
    display: "none",
  },
  loader: {
    color: "#01AA7F",
  },
  cardItem: {
    color: "#FFF",
    border: "1px solid #FFF",
    fontWeight: 700,
    fontSize: "14px",
    borderRadius: "8px",
    padding: "8px 16px",
    fontFamily: "Open Sans",
  },
  defaultCard: {
    color: "#FFF",
    border: "1px solid #CEF6BB",
    fontWeight: 700,
    fontSize: "14px",
    borderRadius: "8px",
    padding: "16px",
    fontFamily: "Open Sans",
  },
  activeTag: {
    padding: "2px 6px",
    backgroundColor: "#CEF6BB",
    color: "#05690D",
    borderRadius: "6px",
    fontWeight: 400,
    fontSize: "12px",
  },
  dialogTitle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "30px",
    fontWeight: 700,
    letterSpacing: -0.15,
    lineHeight: 1.33,
    color: "#FFF",
  },
  dialogTitleWithBorder: {
    marginBottom: "8px",
  },
  actionContainer: {
    border: "1px solid #FFF",
    borderRadius: "8px",
    padding: "8px 16px",
  },
  radioLabel: {
    color: "#FFF",
    fontFamily: "Open Sans",
  },
  radioLabelDescription: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    paddingTop: "10px",
    textAlign: "center",
    paddingBottom: "6px",
  } as CSSProperties,
  dialogHeader: {
    borderBottom: "1px solid grey",
    margin: "0px -32px",
  },
  pointer: {
    cursor: "pointer",
  },
  labelStyle: {
    fontSize: "14px",
    color: "#AAB7C4",
    fontFamily: "Open Sans",
  },
  containerStyle: {
    border: "1px solid #00AED2",
    marginTop: "4px",
    padding: "12px",
    borderRadius: "8px",
    marginBottom: "20px",
    backgroundColor: "#001327",
  },
  validationError: {
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: 1.5,
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
    borderRadius: "4px",
    padding: "16px",
    borderLeft: "2px solid #DC2626",
    margin: "16px 0px",
  },
  formControlLabel: { marginTop: "16px" },
};
